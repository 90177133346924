/**
* Generated theme by Material Theme Generator
* https://material-theme-generator.travetto.io
*/
@use '@angular/material' as mat;

@import '@angular/material/theming';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'Roboto', 0em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'Roboto', 0em),
  title: mat.define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px),
);

// Foreground Elements

// Light Theme Text
$dark-text: #292d39;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

mat.$light-theme-foreground-palette: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

mat.$dark-theme-foreground-palette: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3)
);

// Background config
// Light bg
$light-background: #ffffff;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-alpha-4: rgba(#1b1e27, 0.04);
$dark-bg-alpha-12: rgba(#1b1e27, 0.12);

mat.$light-theme-background-palette: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10
);

// Dark bg
$dark-background: #1b1e27;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#ffffff, 0.04);
$light-bg-alpha-12: rgba(#ffffff, 0.12);

// Background palette for dark themes.
mat.$dark-theme-background-palette: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10
);

// Compute font config
// @include mat-core($fontConfig);

// Theme Config

body {
  --primary-color: #dadada;
  --primary-lighter-color: #f4f4f4;
  --primary-darker-color: #cbcbcb;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$dark-primary-text};
}

$mat-primary: (
  main: #dadada,
  lighter: #f4f4f4,
  darker: #cbcbcb,
  200: #dadada,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $dark-primary-text,
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #797979;
  --accent-lighter-color: #d7d7d7;
  --accent-darker-color: #5c5c5c;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: #797979,
  lighter: #d7d7d7,
  darker: #5c5c5c,
  200: #797979,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: mat.define-dark-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
  @include mat.all-component-themes($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-mdc-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.mat-mdc-paginator {
  background: transparent;
}

.mat-mdc-table {
  background: transparent;
  .mat-mdc-row {
    // background-color: #1b1e27;
    &.highlighted {
      background-color: #3e3e3e;
    }
    &:hover {
      //background-color: #565656;
      // box-shadow: 0 0 10px -3px #b7b7b7
    }
  }
}
// .mat-mdc-dialog-actions {
//     margin: auto;
// }

.mat-mdc-form-field {
  .mdc-text-field--filled {
    padding: 0;
    background-color: #303137;
    border-radius: 4px;
    .mat-mdc-form-field-flex {
      border: 1px solid #dadada;
      border-radius: 4px;
      padding: 0.5vh;
      .mat-mdc-form-field-infix {
        padding-bottom: 0;
      }
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
.mat-mdc-form-field-outline {
  background-color: #303137;
}

.mat-mdc-form-field {
  margin-right: 1vh;

  // include this css class name if the parent mat-form-field is a select component and your child mat-form-field is a text input with icon suffix and has a value
  &.select-search {
    .mat-mdc-text-field-wrapper {
      padding-right: 12px;
    }
  }
}

//todo change active elements to these
.mat-mdc-slide-toggle.mat-accent {
  --mat-mdc-radio-ripple-color: transparent;
  --mat-mdc-radio-checked-ripple-color: transparent;
  --mdc-switch-selected-focus-track-color: #bd9067;
  --mdc-switch-selected-hover-track-color: #bd9067;
  --mdc-switch-selected-pressed-track-color: #bd9067;
  --mdc-switch-selected-track-color: #bd9067;
  --mdc-switch-unselected-icon-color: transparent;
  --mdc-switch-disabled-unselected-icon-color: transparent;
  &.mat-mdc-slide-toggle-checked {
    --mdc-switch-selected-focus-state-layer-color: #fff;
    --mdc-switch-selected-focus-handle-color: #fff;
    --mdc-switch-selected-hover-handle-color: #fff;
    --mdc-switch-selected-pressed-handle-color: #fff;
    --mdc-switch-selected-handle-color: #fff;
    --mdc-switch-selected-hover-state-layer-color: #fff;
    --mdc-switch-selected-pressed-state-layer-color: #fff;
    --mdc-switch-selected-focus-track-color: #bd9067;
    --mdc-switch-selected-hover-track-color: #bd9067;
    --mdc-switch-selected-pressed-track-color: #bd9067;
    --mdc-switch-selected-track-color: #bd9067;
    --mdc-switch-selected-icon-color: transparent;
    --mdc-switch-disabled-selected-icon-color: transparent;
  }

  label {
    margin-bottom: 0;
    margin-left: 0.25rem;
    margin-right: 0.5rem;
  }
}

.mat-mdc-snack-bar-container {
  // override with default snackbar theme
  --mdc-snackbar-container-color: #fff;
  --mat-mdc-snack-bar-button-color: #000;
  --mdc-snackbar-supporting-text-color: #000;

  // define custom snackbar classes here to use for panelClass config
  &.snackbar-notification {
    --mdc-snackbar-container-color: #fff;
    --mat-mdc-snack-bar-button-color: #000;
    --mdc-snackbar-supporting-text-color: #000;
  }
}

// mat-checkbox
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-disabled-selected-checkmark-color: rgba(0, 0, 0, 0.24);
  --mdc-checkbox-selected-checkmark-color: rgba(22, 22, 66, 0.64);
  --mdc-checkbox-selected-icon-color: #afafbf;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  label {
    margin-bottom: 0;
  }
}

// mat-radio-button
.mat-mdc-radio-button {
  label {
    margin-bottom: 0;
  }
}

// mat-card elevation fix
@for $i from 0 through 24 {
  .mat-mdc-card.mat-elevation-z#{$i} {
    @include mat.elevation($i);
  }
}
