
.osmb {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: default;
  }
  
  .osmb-viewport {
    width: 100%;
    height: 100%;
  }
  
  .osmb-attribution {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 1px 3px;
    background: rgba(255, 255, 255, 0.5);
    font: 11px sans-serif;
    z-index: 10;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
  
  @media screen and (max-width: 480px) {
    .osmb-attribution {
      right: auto;
      left: 0;
    }
  }
  
  .osmb-attribution a {
    text-decoration: none;
    color: #0078A8;
  }
  
  .osmb-attribution a:visited {
    text-decoration: none;
    color: #0078A8;
  }
  
  .osmb-attribution a:hover {
    text-decoration: underline;
    color: #0078A8;
  }
  
  .osmb-popup {
    position: absolute;
  }