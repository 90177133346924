/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
$fa-font-path: '../node_modules/font-awesome/fonts';
// @import '~font-awesome/scss/font-awesome.scss';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

div.ol-zoom {
  left: auto;
  right: 2vh;
  & > button {
    float: left;
  }
}

.early {
  background: #ebdd79;
  color: #313540;
}
.on-time {
  background: #70c460;
}
.late {
  background: #eb5d5d;
}

.statusGreen {
  color: #029002;
}
.statusYellow {
  color: #ffff00;
}
.statusOrange {
  color: #ffa500;
}
.statusRed {
  color: #ff0000;
}
.statusGray {
  color: #939393;
}
.link {
  color: blue;
  cursor: pointer;
}
.colorWhite {
  color: #fff;
}
.color-brown {
  color: #e0c39b;
}

.bgGreen {
  background-color: #029002;
}
.bgYellow {
  background-color: #ffff00;
}
.bgOrange {
  background-color: #df5e42;
}
.bgRed {
  background-color: #ff0000;
}
.bgGray {
  background-color: #939393;
}
.bgWhite {
  background-color: #fff;
}

.no-padding {
  padding: 0;
}

.padding-10 {
  padding: 10px;
}

.margin-auto {
  margin: auto;
}
.no-margin {
  margin: 0;
}
.no-margin-imp {
  margin: 0 !important;
}
.mar-top-10 {
  margin-top: 10px !important;
}
.mt-10per {
  margin-top: 10% !important;
}

.mar-bot-5 {
  margin-bottom: 5px !important;
}
.mar-bot-10 {
  margin-bottom: 10px !important;
}
.mar-bot-20 {
  margin-bottom: 20px !important;
}

.mar-left-5 {
  margin-left: 5px !important;
}

.mar-left-10 {
  margin-left: 10px !important;
}

.mar-right-10 {
  margin-right: 10px !important;
}

.mar-top-bot-2vh {
  margin: 2vh 0;
}

.textWhite {
  color: #fff !important;
}
.textBlack {
  color: #000 !important;
}
.textBlue {
  color: #bbe1fa;
}
.textRed {
  color: #ff0000;
}
.textYellow {
  color: #ffff00;
}
.textGreen {
  color: #008000;
}

.ps {
  .ps__rail-y,
  .ps__rail-x {
    background-color: transparent !important;
  }
}

.ladder-group {
  perfect-scrollbar > .ps.ps--active-y > .ps__rail-y {
    // background-color: pink !important;
    left: 0 !important;
    // .ps__thumb-y {
    // background-image: 'url(/assets/images/icon_calendar.png)';
    // background-image: url('../src/assets/images/icon/doubleUp.png');
    // background: url('../src/assets/images/icon/arrows.svg') center / 100% no-repeat;
    // }
  }
}

// #contentLine{
//     position:relative;
//   }
// .mydiv{
//     border:1px solid #368ABB;
//     background-color:#43A4DC;
//     position:absolute;
// }
// .mydiv:after{
// content:no-close-quote;
//     position:absolute;
//     top:50%;
//     left:50%;
//     background-color:black;
//     width:4px;
//     height:4px;
//     border-radius:50%;
//     margin-left:-2px;
//     margin-top:-2px;
// }
// #div1{
//     left:200px;
//     top:200px;
//     width:50px;
//     height:50px;
// }
// #div2{
//     left:0px;
//     top:500px;
//     width:50px;
//     height:40px;
// }
// #line{
//     position:absolute;
//     width:2px;
//     margin-top:-1px;
//     background-color:red;
// }

// :host{
//     ::ng-deep {

//       table{
//         color: #029002;
//         tr {
//           td,th{
//             border: 1px solid #ffff00;
//           }
//         }
//       }
//     }
// }

// :host{
//     ::ng-deep {
html {
  overflow: hidden;
}
.calendar-icon {
  background: url('../src/assets/images/icon/icon_calendar.png') center / 75%
    no-repeat;
  border: 1px solid #ced4da;
}
// }
// }

.driver-status,
.is-temporary {
  .ui-helper-clearfix {
    background-color: transparent;
    border: 1px solid #a6a6a6;
    border-width: 0 0 2px;
    //   min-width: 100%;
    border-radius: 0;
  }
  .ui-inputtext,
  .ui-corner-right {
    background-color: transparent !important;
    line-height: 1em;
    color: #fff;
  }
}
.ui-dropdown {
  min-width: 100% !important;
}
.driver-table {
  .ui-paginator {
    background: transparent;
    border: none;
    padding-top: 1vh;
    color: #e2e2e2;
    .ui-paginator-current {
      color: #e2e2e2;
    }
  }
}
.ui-table-scrollable-header {
  overflow: unset !important;
}
.joiningDateCalendar {
  .ui-datepicker-calendar {
    width: 30vh;
  }
}

.checkbox-slider {
  // .switch {
  vertical-align: sub;
  position: relative;
  display: inline-block;
  width: 3.9vh;
  height: 2vh;
  margin: 0;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .sw-cb:checked + .slider {
    background-color: #9f754f;
  }

  .sw-cb:focus + .slider {
    box-shadow: 0 0 1px #9f754f;
  }

  .sw-cb:checked + .slider:before {
    -webkit-transform: translateX(2vh);
    -ms-transform: translateX(2vh);
    transform: translateX(2vh);
  }
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #31333f;
    border: 1px solid;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &::before {
      position: absolute;
      content: '';
      height: 1.7vh;
      width: 1.7vh;
      left: 0vh;
      // bottom: -.1vh;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  .slider.round:before {
    border-radius: 50%;
  }
}

.loader-group {
  text-align: center;
  padding: 20vh;
  background-color: #262c3c;
  border-radius: 1vh;
  .fa-spin {
    color: #aeaeae;
    font-size: 5vh;
  }
}
.open-window {
  font-size: 1.5vh;
  padding-left: 0.5vh;
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}

.visibility-hidden {
  visibility: hidden;
}

.width100 {
  width: 100%;
}

.width50 {
  width: 50% !important;
}

button.mdc-button.btn-dark {
  background-color: #232227;
  &:focus {
    outline: none;
  }
}
.btn-white {
  background-color: #fff !important;
  color: #212121 !important;
}

.btn-white:disabled {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.btn-white:hover {
  background-color: #eee !important;
}

.btn-white:active,
.btn-white:focus {
  background-color: #ccc !important;
}

.btn-med {
  min-height: 43px;
  min-width: auto !important;
  font-size: medium;
}

.error {
  color: #920000;
  font-style: italic;
}
.valid {
  color: #2ead17;
  font-style: italic;
}

@keyframes redYellowAlarm {
  0% {
    background: #7f3131;
  }
  50% {
    background: #7f6e31;
  }
  100% {
    background: #7f3131;
  }
}

.bounce {
  display: inline-block;
  position: relative;
  -moz-animation: bounce 0.5s infinite linear;
  -o-animation: bounce 0.5s infinite linear;
  -webkit-animation: bounce 0.5s infinite linear;
  animation: bounce 0.5s infinite linear;
  color: 000;
}
@-webkit-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
@-moz-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
@-o-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
@-ms-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
@keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-mdc-button-wrapper {
    color: transparent;
  }
  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner 0.8s linear infinite;
  }
}

.font-size-inherit {
  font-size: inherit !important;
}

::ng-deep {
  table {
    width: 100%;
    background: transparent;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-mdc-header-cell,
  .mat-mdc-cell,
  .mat-mdc-select-value,
  svg {
    color: #dadada;
  }
  .mat-mdc-paginator {
    background: transparent;
    color: #dadada;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
    color: #dadada;
  }
  button {
    color: #dadada;
    background: #585858;
    &:disabled {
      opacity: 0.5;
      color: #dadada;
      background: #585858;
    }
  }
  mat-footer-row,
  mat-header-row,
  mat-row,
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell,
  th.mat-mdc-header-cell {
    border-bottom-color: #2c2c2c;
  }
}
button {
  &:focus {
    outline: none;
  }
}
/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
mat-dialog-actions {
  justify-content: flex-end;
}
.mat-mdc-table {
  width: 100%;
}

.title-header {
  padding: 1.5vh 0;
}

.color-dadada {
  color: #dadada;
}
.minWidthAuto {
  min-width: auto !important;
}
.minWidth50 {
  min-width: 50%;
}
.pointer {
  cursor: pointer;
}

//map
.mapboxgl-ctrl-logo {
  display: none !important;
}

.vertical-middle {
  vertical-align: middle;
}

//classic component
.classic-input {
  border: 1px solid #dadada !important;
  border-radius: 5px;
  background: #303137 !important;
  padding: 1vh !important;
}

.max-height-1vh {
  max-height: 1vh;
}

.sm-italic {
  font-size: 12px;
  font-style: italic;
}

.v-align-sub {
  vertical-align: sub;
}

.ne,
.nel {
  background: #440e62;
}
.ew,
.cg,
.ewl,
.cgl {
  background: #00a651;
}
.cc,
.ccl {
  background: #ceb60d;
}
.ns,
.nsl {
  background: #a00303;
}
.dt,
.dtl {
  background: #1765bf;
}
.te,
.tel {
  background: #9d5a19;
}
.lrt,
.bp,
.sk,
.pg {
  background: #708372;
}

.font-sm {
  font-size: small;
}
.font-md {
  font-size: medium;
}
.font-lg {
  font-size: large;
}
.font-30 {
  font-size: 30px;
}
.font-bold {
  font-weight: bold;
}
.text-uppercase {
  text-transform: uppercase;
}

.div-disabled {
  pointer-events: none;
  opacity: 0.5;
}

//drag and drop
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.dd-box:last-child {
  border: none;
}

.dd.cdk-drop-list-dragging .dd-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.dd-custom-placeholder-sm {
  background: #cccccc80;
  border: dotted 3px #999;
  border-radius: 4px;
  min-height: 40px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.dd-custom-placeholder-md {
  background: #cccccc80;
  border: dotted 3px #999;
  border-radius: 4px;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

//end drag and drop

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
  background: #000;
  opacity: 0.75 !important;
}

.d-table {
  display: table;
}

.d-none {
  display: none;
}

.d-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.pos-abs {
  position: absolute;
}

// .alarm-info {

// }
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;

  // custom material button color. use color="white" to use
  .mdc-button.mat-white,
  .mat-mdc-stroked-button.mat-white {
    color: #fff;
  }
  .mat-mdc-unelevated-button.mat-white:hover,
  .mat-mdc-raised-button.mat-white:hover {
    color: #212121;
    background-color: #eaeaec;
  }

  .mat-mdc-unelevated-button.mat-white:disabled,
  .mat-mdc-raised-button.mat-white:disabled {
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.12);
  }

  .mat-mdc-unelevated-button:not(:disabled, :hover).mat-white,
  .mat-mdc-raised-button.mat-white,
  .mat-mdc-fab.mat-white,
  .mat-mdc-mini-fab.mat-white {
    color: #212121;
    background-color: #fff;
    --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  }
  .mat-mdc-icon-button.mat-white {
    color: #fff;
  }

  // .mat-mdc-select-panel {
  //     overflow-x: hidden;
  //     max-height: 50vh;
  //     min-height: 32px;
  // }
  // material menu custom scroll
  .mdc-menu-surface::-webkit-scrollbar {
    width: 4px;
  }

  /* Handle */
  .mdc-menu-surface::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* Handle on hover */
  .mdc-menu-surface::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

$someColor: #37383e;
::ng-deep {
  .timepicker {
    input {
      cursor: pointer;
    }
    .timepicker__header {
      background-color: $someColor !important;
    }

    .clock-face__clock-hand {
      background-color: $someColor !important;

      &::after {
        background-color: $someColor !important;
      }

      &::before {
        border-color: $someColor !important;
      }
    }

    .clock-face__number {
      > span.active {
        background-color: $someColor !important;
      }
    }

    button:not(.timepicker-dial__item) {
      color: $someColor !important;
    }
  }
}

.custom-snack-bar-panel-success {
  &.mat-mdc-snack-bar-container {
    // color: #fff;
    // background: #51a351;
    --mdc-snackbar-supporting-text-color: #fff;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-container-color: #51a351;
    --mat-mdc-button-persistent-ripple-color: rgba(255, 255, 255, 0.05);
  }
}
.custom-snack-bar-panel-error {
  &.mat-mdc-snack-bar-container {
    // color: #fff;
    // background: #bf1044;
    --mdc-snackbar-supporting-text-color: #fff;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-container-color: #d32f2f;
    --mat-mdc-button-persistent-ripple-color: rgba(255, 255, 255, 0.05);
  }
}

.height-50vh {
  height: 50vh;
}
.height-100vh {
  height: 100vh !important;
}

.loader {
  background: url('../src/assets/images/loader.gif') center / 100% no-repeat;
  width: 50px;
  height: 50px;
}

.custom-scroll {
  overflow-x: hidden;
  max-height: 50vh;
  min-height: 32px;
}
.custom-scroll::-webkit-scrollbar,
.custom-page-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb,
.custom-page-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover,
.custom-page-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-scroll::-webkit-scrollbar-corner,
.custom-page-scroll::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

/* Track */
// .custom-scroll::-webkit-scrollbar-track {
//     background: #f1f1f1;
// }

.f-r {
  float: right;
}

// added class for mat-menu to remove width limitations
.mat-mdc-menu-panel.popup-panel.popup-menu {
  max-width: fit-content;
}

.multi-select-panel {
  .mat-pseudo-checkbox {
    display: none;
  }
  .mdc-list-item__primary-text {
    width: 100%;
  }
}
